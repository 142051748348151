import axios from 'axios';
import API from '@/api';
import { Notify } from 'vant';
// 微信公众号的 appId是 wx5b10e76f73874daa
const APPID = 'wx5b10e76f73874daa'
import wx from "weixin-js-sdk";
export default {
	/**
	 * 分享
	 */
	async goShare(params) {
		console.log('params', params);
		let local = location.href.split('#')[0]
		// var time = Date.parse(new Date()).toString();//获取到毫秒的时间戳，精确到毫秒
		// time = time.substr(0, 10);//精确到秒
		const res = await axios({
			url: API.API_WX_JSAPI_TICKET,
			method: "post",
			data: {
				targetUrl: local,
				wxAppId: APPID
			}
		});
		console.log('res',res);
		console.log('res.data',res.data);
		
		if (res.data) {
			Notify({ type: 'warning', message: '请点击右上角分享(发送给朋友)然后点击查看' });
			wx.config({
				debug: true,
				appId: APPID,
				timestamp: res.data.timestamp,
				nonceStr: res.data.noncestr,
				signature: res.data.signature,
				jsApiList: ['checkJsApi',
					'getNetworkType',//网络状态接口
					'onMenuShareTimeline',
					'onMenuShareAppMessage'
				] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			});

			wx.ready(function () {
				// 分享给朋友
				wx.onMenuShareAppMessage({
					title: params.shareTitle, // 分享标题
					desc: params.shareText, // 分享描述
					link: params.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: params.shareImgUrl, // 分享图标
					type: '', // 分享类型,music、video或link，不填默认为link
					dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
					success: function () {
						// 用户确认分享后执行的回调函数
					},
					cancel: function () {
						// 用户取消分享后执行的回调函数
					}
				});
				// 分享到朋友圈
				wx.onMenuShareTimeline({
					title: params.shareTitle, // 分享标题
					desc: params.shareText, // 分享描述
					link: params.shareUrl, // 分享链接
					imgUrl: params.shareImgUrl, // 分享图标
					success: function (res) { },
					cancel: function () { }
				});

			});
			//初始化jsapi接口 状态
			wx.error(function (res) {
				alert("调用微信jsapi返回的状态:" + res.errMsg);
			});
		}
	}
}